import { i18n, setLocale } from '@/lang';
import { toast } from '@/lib/toast';
import { globalState } from '@/services/globals';
import { axiosCompatible as Ajax } from '@/services/http';

export const appModule = {
  namespaced: true,

  state: {
    userInfo: { userSettings: {}, userPhones: [] },
    userPermissions: null,
    userBranches: [],
    userTradingUnitTypes: [],
    userReceiptMethods: [],
    mobileBreak: false,
    constServiceData:{},
    userOrganizedData: {},
    recieverContacts: {},
    brancheAllPhysicalAccount: {},
    emailableBranch: null
  },

  getters: {
    userId: (state) => state.userInfo.id,
    userInfo: (state) => state.userInfo,
    userPermissions: (state) => state.userPermissions,
    userBranches: (state) => state.userBranches,
    userTradingUnitTypes: (state) =>
      state.userTradingUnitTypes.map((item) => {
        if (item.insertPermission) {
          return item.tradingUnitTypeId;
        }
      }),
    userReceiptMethods: (state) => state.userReceiptMethods,
    userActiveBranch: (state) =>
      state.userBranches.find((x) => x.isActive) ?? {
        city: {
          name: '',
          enName: '',
          phoneCode: null,
          countryId: null,
          countryName: '',
          countryShortCode: '',
          id: null,
        },
        costCenters: [],
        paymentTransferTypes: [],
        receiptMethods: [],
        tradingUnits: [],
      },
    useGregorianCalendar: (state) => state.userInfo.userSettings.useGregorianCalendar,
    timeZone: (state) => state.userInfo.userSettings.timeZone,
    timeZoneOffset: (state) => state.userInfo.userSettings.timeZoneOffset,
    userIsAuthenticated: (state) => !!state.userInfo.id,
    mobileBreak: (state) => state.mobileBreak,
    constServiceData: (state) => state.constServiceData,
    userOrganizedData: (state) => state.userOrganizedData,    
    recieverContacts: (state) => state.recieverContacts,  
    brancheAllPhysicalAccount: (state) => state.brancheAllPhysicalAccount,
    emailableBranch: (state) => state.emailableBranch 
  },

  actions: {
    async loadAppInfoAsync({ commit, dispatch }) {
      const [userInfo, userPermissions] = await Promise.all([
        dispatch('fetchUserInfoAsync', true),
        dispatch('fetchUserPermissionsAsync'),
      ]);

      const [userBranches, userTradingUnitTypes, userReceiptMethods] = await Promise.all([
        dispatch('fetchUserBranchesAsync', userInfo.id),
        dispatch('fetchUserTradingUnitTypesAsync', userInfo.id),
        dispatch('fetchUserReceiptMethodsAsync', userInfo.id),
      ]);

      commit('setState', {
        userInfo,
        userPermissions,
        userBranches,
        userTradingUnitTypes,
        userReceiptMethods,
      });
    },
    async fetchUserInfoAsync({ commit }, setState = false) {
      try {
        const r = await Ajax.get('/api/v1/Profile/GetCurrentUserProfileInfo');
        const lang = r.data.userSettings.language;
        if (i18n.locale !== lang) {
          await setLocale(lang);
        }

        if (setState) commit('setUserInfo', r.data);
        return r.data;
      } catch (error) {
        toast.error(error.name, error.message);
        throw error;
      }
    },
    async fetchUserPermissionsAsync() {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/Security/GetCurrentEmployeePermissionSet')
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            toast.error(e.name, e.message);
            reject(e);
          });
      });
    },
    async fetchUserBranchesAsync(_store, userId) {
      return Ajax.get('/api/v1/Organization/GetEmployeesBranches?id=' + userId)
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          toast.error(e.name, e.message);
          throw e;
        });
    },
    async fetchUserTradingUnitTypesAsync(_store, userId) {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/Organization/GetEmployeesTradingUnitTypes?id=' + userId)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            toast.error(e.name, e.message);
            reject(e);
          });
      });
    },
    async fetchUserReceiptMethodsAsync(_store, userId) {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/Organization/GetEmployeeReceiptMethods?id=' + userId)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            toast.error(e.name, e.message);
            reject(e);
          });
      });
    },
    async setLanguageAsync(_store, language) {
      await setLocale(language);
      return Ajax.post('/api/v1/Profile/SetLanguage', { language }).then((r) => r.data);
    },
    async setActiveBranchAsync({ state, commit, dispatch }, branchId) {
      await Ajax.post('/api/v1/Organization/SetActiveBranch', { id: branchId }).catch((e) => {
        toast.error(e.name, e.message);
        throw e;
      });
      const branches = await dispatch('fetchUserBranchesAsync', state.userInfo.id);
      commit('setUserBranches', branches);
    },
    hasPermission({ state, getters }, permission: string) {
      if (!getters.userIsAuthenticated || !state.userPermissions) return false;
      // if (state.userPermissions[permission] === undefined) {
      //   toast.error('PermissionNotFound', permission);
      // }
      return !!state.userPermissions[permission];
    },
    hasAnyPermission({ state, getters }, permissions: string[]) {
      if (!getters.userIsAuthenticated || !state.userPermissions) return false;
      return permissions.some((prm) => state.userPermissions[prm]);
    },
    setActiveMenu({ commit }, menuItemName) {
      commit('setActiveMenu', menuItemName);
    },
  },

  mutations: {
    setState(state, payload) {
      state.userInfo = payload.userInfo;
      globalState.permissions = Object.freeze(payload.userPermissions);
      state.userPermissions = payload.userPermissions;
      state.userBranches = payload.userBranches;
      state.userTradingUnitTypes = payload.userTradingUnitTypes;
      state.userReceiptMethods = payload.userReceiptMethods;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setUserBranches(state, branches) {
      state.userBranches = branches;
    },
    setMobileBreak(state, value) {
      state.mobileBreak = value;
    },
    setConstServiceData(state, data) {
      state.constServiceData = data;
    },
    setUserOrganizedData(state, data) {
      state.userOrganizedData = data;
    },
    setRecieverContacts(state, data) {
      state.recieverContacts = data;
    },
    setBrancheAllPhysicalAccount(state, data) {
      state.brancheAllPhysicalAccount = data;
    },
    setEmailableBranch(state, data) {
      state.emailableBranch = data;
    },
  },
};
